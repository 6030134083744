<template>
  <v-card class="rounded-lg">
    <v-card-text class="pt-5">
      <div class="font-weight-bold ml-8 mb-2 mt-4">
        Información personal de la victima
      </div>

      <v-timeline align-top dense>
        <v-timeline-item color="accent" small>
          <div>
            <div class="font-weight-normal">
              <strong>Domicilio</strong>
            </div>
            <div class="my-2">
              {{ user.street + " No." + user.num_ext }}
              <br />
              {{ "Colonia: " + user.colony + ", Nay." }}
            </div>
          </div>
        </v-timeline-item>
        <v-timeline-item color="accent" small>
          <div>
            <div class="font-weight-normal">
              <strong>Edad</strong>
            </div>
            <div class="my-2">
              {{ formattedDate }}
            </div>
          </div>
        </v-timeline-item>
        <v-timeline-item color="accent" small>
          <div>
            <div class="font-weight-normal">
              <strong>Complexión</strong>
            </div>
            <div class="my-2">
              {{ user.complexion }}
            </div>
          </div>
        </v-timeline-item>
        <v-timeline-item color="accent" small>
          <div>
            <div class="font-weight-normal">
              <strong>Descripción del cabello</strong>
            </div>
            <div class="my-2">
              {{ user.hair }}
            </div>
          </div>
        </v-timeline-item>
        <v-timeline-item color="accent" small>
          <div>
            <div class="font-weight-normal">
              <strong>Color de ojos</strong>
            </div>
            <div class="my-2">
              {{ user.eyes_color }}
            </div>
          </div>
        </v-timeline-item>
        <v-timeline-item color="accent" small>
          <div>
            <div class="font-weight-normal">
              <strong>Color de piel</strong>
            </div>
            <div class="my-2 d-flex flex-row align-center">
              <div class="skin-circle mr-2" :class="skinColorComputed"></div>
              {{ user.skin_color }}
            </div>
          </div>
        </v-timeline-item>
        <v-timeline-item color="accent" small>
          <div>
            <div class="font-weight-normal">
              <strong>Estatura</strong>
            </div>
            <div class="my-2">
              {{ user.height }}
            </div>
          </div>
        </v-timeline-item>
        <v-timeline-item color="accent" small>
          <div>
            <div class="font-weight-normal">
              <strong>Señas particulares</strong>
            </div>
            <div class="my-2">
              {{ user.particular_signs }}
            </div>
          </div>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>
<style scoped>
.skin-circle {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  border: 3px solid #9e54d9 !important;
}
</style>
<script>
import moment from "moment";

export default {
  props: ["user"],
  computed: {
    formattedDate() {
      let date = moment(this.user.age);
      return moment().diff(date, "years") + " años";
    },
    skinColorComputed() {
      if (this.user.skin_color == "Albina") {
        return "skin_tone_1";
      }
      if (this.user.skin_color == "Blanca") {
        return "skin_tone_2";
      }
      if (this.user.skin_color == "Caucastica") {
        return "skin_tone_3";
      }
      if (this.user.skin_color == "Morena Claro") {
        return "skin_tone_4";
      }
      if (this.user.skin_color == "Morena") {
        return "skin_tone_5";
      }
      if (this.user.skin_color == "Morena oscura") {
        return "skin_tone_6";
      }
    },
  },
};
</script>