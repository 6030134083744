<template>
  <v-card class="rounded-lg">
    <v-card-title>
      <h3>Elementos en servicio</h3>
    </v-card-title>
    <v-card-text v-if="police == null">
      <v-text-field
        outlined
        label="Buscar elemento"
        hide-details=""
        v-model="search"
      ></v-text-field>
    </v-card-text>
    <v-divider></v-divider>
    <v-list v-if="!!police">
      <v-list-item-group color="primary">
        <v-list-item
          class="d-flex align-center justify-center"
          @click="onPoliceClick(police.id, police.name, police.lastname, 0)"
        >
          <v-list-item-icon>
            {{ police.id }}
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ police.name + " " + police.lastname }}
            </v-list-item-title>
          </v-list-item-content>
          <v-chip color="success"> Asignado </v-chip>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-list v-else>
      <v-list-item-group v-model="selectedItem" color="primary">
        <v-list-item
          v-for="(item, i) in dataFiltered"
          :key="i"
          @click="onPoliceClick(item.id, item.name, item.lastname, i)"
          class="d-flex align-center justify-center"
        >
          <v-list-item-icon>
            {{ item.id }}
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.name + " " + item.lastname }}
            </v-list-item-title>
          </v-list-item-content>
          <v-btn
            small
            color="primary"
            outlined
            rounded
            :loading="isLoading"
            :disabled="isLoading"
            @click.stop="assignOfficer(item.id)"
            >Enviar agente</v-btn
          >
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: ["policias", "police_report_id", "police"],
  data: () => ({
    selectedItem: null,
    search: "",
    isLoading: false,
  }),
  methods: {
    onPoliceClick(id, name, lastname, index) {
      this.selectedItem = index;
      this.$emit("selectPolice", id + " | " + name + " " + lastname);
    },
    assignOfficer(police_id) {
      let formData = new FormData();
      this.isLoading = true;
      formData.append("id", this.police_report_id);
      formData.append("police_id", police_id);
      this.axios({
        method: "post",
        url: this.server + "/police-reports/assign-officer",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${"Bearer"}${" "}${this.user.token}`,
        },
      }).then((response) => {
        this.isLoading = false;
        console.log(response.data);
      });
    },
  },
  computed: {
    ...mapState({
      server: (state) => state.cache.server,
      user: (state) => state.cache.user,
    }),
    dataFiltered() {
      return this.policias.filter((item) => {
        return (
          item.name.toLowerCase().includes(this.search) ||
          item.lastname.toLowerCase().includes(this.search) ||
          item.id == this.search
        );
      });
    },
  },
};
</script>
