<template>
  <v-card class="rounded-lg mt-8">
    <v-card-text
      class="pt-5 d-flex justify-center align-center flex-column"
      v-if="report != null"
    >
      <v-icon class="mt-4" size="50" color="primary">
        mdi-clipboard-check
      </v-icon>
      <p class="primary--text text-center mt-3 mb-6">
        Hay un reporte generado para esta emergencia del día
        <br />
        <b>{{ formattedDate }}</b>
      </p>
      <v-btn
        rounded
        depressed
        color="primary"
        class="mb-6"
        @click="goToDetails"
      >
        Ver reporte
      </v-btn>
    </v-card-text>
    <v-card-text
      class="pt-5 d-flex justify-center align-center flex-column"
      v-else
    >
      <v-icon size="50" color="grey lighten-1"> mdi-clipboard-file </v-icon>
      <p class="grey--text text--lighten-1 text-center mt-3">
        Aún no se genera ningún reporte de esta emergencia
      </p>
    </v-card-text>
  </v-card>
</template>
<script>
import moment from "moment";
export default {
  props: ["report"],
  methods: {
    goToDetails() {
      this.$store.dispatch("cache/setShowModal", true);
    },
  },
  computed: {
    formattedDate() {
      if (!!this.report) {
        return moment(this.report.created_at).format("LLL");
      }
      return "Sin fecha";
    },
  },
};
</script>