<template>
  <v-card class="rounded-lg">
    <v-card-text class="pt-5">
      <div class="font-weight-bold ml-8 mb-2 mt-4">Datos Generales</div>

      <v-timeline align-top dense>
        <v-timeline-item color="accent" small>
          <div>
            <div class="font-weight-normal">
              <strong>Estatus</strong>
            </div>
            <div class="my-2">
              <v-chip
                color="warning"
                text-color="white"
                v-if="status === 'Pendiente'"
                small
              >
                Pendiente
              </v-chip>
              <v-chip
                color="info"
                text-color="white"
                v-if="status === 'En camino'"
                small
              >
                En camino
              </v-chip>
              <v-chip
                color="success"
                text-color="white"
                v-if="status === 'Atendido'"
                small
              >
                Atendido
              </v-chip>
              <div class="mt-3">
                {{ updated | date }}
              </div>
            </div>
          </div>
        </v-timeline-item>
        <v-timeline-item color="accent" small>
          <div>
            <div class="font-weight-normal">
              <strong>Fecha de reporte</strong>
            </div>
            <div class="my-2">
              {{ created | date }}
            </div>
          </div>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>
<script>
import moment from "moment";
export default {
  props: ["status", "created", "updated"],
};
</script>