<template>
  <div class="about">
    <v-container>
      <v-row class="my-3">
        <v-col cols="12" md="10" class="d-flex flex-row align-center">
          <h1 class="mb-0">Administradores registrados</h1>
        </v-col>
        <v-col cols="12" md="2" class="end">
          <v-btn
            color="primary"
            large
            block
            rounded
            @click="dialogCreate = true"
          >
            <v-icon left>mdi-plus</v-icon>
            Agregar
          </v-btn>
        </v-col>
      </v-row>
      <v-text-field
        hide-details
        outlined
        v-model="search"
        name="search"
        ref="search"
        prepend-inner-icon="mdi-magnify"
        label="Buscar"
        single-line
        clearable
        class="mb-4"
      ></v-text-field>
      <v-data-table
        :search="search"
        :headers="headers"
        :items="data"
        :items-per-page="20"
        class="elevation-1 mt-6"
      >
        <template v-slot:item.name="{ item }">
          {{ item.name + " " + item.lastname }}
        </template>
        <template v-slot:item.phone="{ item }">
          {{ item.phone | phone }}
        </template>
        <template v-slot:item.email="{ item }">
          <v-chip label color="primary" outlined small>
            {{ item.email }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            class="mx-2"
            fab
            dark
            small
            depressed
            color="error"
            v-on:click="gotDelete(item.id)"
          >
            <v-icon dark> mdi-cancel </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog v-model="dialogCreate" persistent max-width="600px">
      <v-card class="rounded-lg">
        <v-card-title class="primary py-6 white--text">
          Crear usuario
        </v-card-title>
        <v-card-text>
          <v-container class="py-6">
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  hide-details
                  outlined
                  label="Nombres*"
                  required
                  v-model="name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  hide-details
                  outlined
                  label="Apellidos*"
                  v-model="lastname"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  hide-details
                  outlined
                  label="Correo electrónico(Usuario)*"
                  required
                  v-model="email"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  hide-details
                  outlined
                  label="Permisos *"
                  required
                  v-model="permissionsSelected"
                  :items="permissions"
                  multiple
                ></v-select>
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field
                  hide-details
                  outlined
                  label="Contraseña*"
                  :type="showPassword ? 'text' : 'password'"
                  v-model="password"
                  required
                  :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-btn
                  @click="generateRandomPassword"
                  color="primary"
                  depressed
                  x-large
                  block
                >
                  Generar
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
          <small>*Campos requeridos</small>
        </v-card-text>
        <v-card-actions>
          <v-row class="mb-4 mr-4">
            <v-col cols="12" md="6" lg="4" offset-lg="4">
              <v-btn
                block
                color="error"
                :loading="loading"
                text
                @click="
                  dialogCreate = false;
                  cleanDialog();
                "
              >
                Cancelar
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-btn block color="primary" :loading="loading" @click="goSave()">
                Guardar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar">
      Ocurrió un error, revise la información ingresada e intente nuevamente.

      <template v-slot:action="{ attrs }">
        <v-btn color="accent" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "Administradores",
  mounted() {
    this.getData();
  },
  computed: {
    ...mapState({
      server: (state) => state.cache.server,
      user: (state) => state.cache.user,
    }),
  },
  data() {
    return {
      snackbar: false,
      name: null,
      lastname: null,
      password: null,
      email: null,
      dialogCreate: false,
      search: null,
      headers: [
        { text: "Id Registro", align: "start", sortable: true, value: "id" },
        { text: "Nombre", value: "name" },
        { text: "Correo", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Detalles", value: "actions" },
      ],
      data: [],
      loading: false,
      showPassword: false,
      permissions: ["Policias", "Reportes", "Administradores"],
      // , "Estadísticas"
      permissionsSelected: null,
    };
  },
  methods: {
    cleanDialog() {
      this.phone = null;
      this.name = null;
      this.lastname = null;
      this.email = null;
      this.password = null;
    },
    goSave() {
      if (!!this.name && !!this.lastname && !!this.email && !!this.password) {
        var self = this;
        self.loading = true;
        let formData = new FormData();
        formData.append("name", this.name);
        formData.append("lastname", this.lastname);
        formData.append("email", this.email);
        formData.append("password", this.password);
        formData.append("permissions", this.permissionsSelected.join(","));
        this.axios({
          method: "post",
          url: self.server + "/user/store-admin",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${"Bearer"}${" "}${self.user.token}`,
          },
        })
          .then((response) => {
            self.loading = false;
            self.dialogCreate = false;
            self.getData();
            self.cleanDialog();
          })
          .catch(function (error) {
            self.loading = false;
            self.snackbar = true;
          });
      }
    },
    gotDelete(id) {
      var self = this;
      let formData = new FormData();
      formData.append("id", id);
      this.axios({
        method: "post",
        url: self.server + "/user/delete",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${"Bearer"}${" "}${self.user.token}`,
        },
      }).then((response) => {
        self.getData();
      });
    },
    getData() {
      var self = this;
      let formData = new FormData();
      formData.append("role", "Administrador");
      this.axios({
        method: "post",
        url: self.server + "/user/list",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${"Bearer"}${" "}${self.user.token}`,
        },
      }).then((response) => {
        self.data = response.data;
      });
    },
    generateRandomPassword() {
      let partOne = Math.random().toString(36).substr(2, 5).toLowerCase();
      let partTwo = Math.random().toString(36).substr(2, 5).toLowerCase();
      let partThree = Math.random().toString(36).substr(2, 5).toLowerCase();
      this.password = partOne + "-" + partTwo + "-" + partThree;
    },
  },
};
</script>