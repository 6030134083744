<template>
  <v-container fluid class="grey lighten-4 pb-12">
    <v-row class="my-3" align="center" justify="center">
      <v-col cols="12" class="d-flex flex-row align-center">
        <v-btn color="accent" rounded @click="$router.go(-1)">
          <v-icon left>mdi-arrow-left</v-icon>
          Regresar
        </v-btn>
        <h1 class="ml-6 mb-0">Detalles de la emergencia</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8" order="2" order-md="1">
        <v-row>
          <v-col cols="12">
            <v-card class="rounded-lg">
              <v-card-title
                class="d-flex justify-center align-center flex-column white--text pt-5 primary"
              >
                <v-avatar
                  size="100"
                  color="white"
                  style="border: 2px solid #fff"
                >
                  <img
                    v-if="data.user.front_image_64 != null"
                    cover
                    height="100"
                    width="100"
                    alt="user"
                    :src="data.user.front_image_64"
                  />
                  <v-icon v-else class="mx-2" color="white">
                    mdi-account-multiple
                  </v-icon>
                </v-avatar>
                <p class="text-center mt-6">
                  {{ data.user.name + " " + data.user.lastname }}
                  <br />
                  <small>{{ data.user.phone | phone }}</small>
                  <br />
                </p>
                <v-chip
                  style="position: absolute; top: 1rem; right: 1rem"
                  outlined
                  color="white"
                >
                  {{ data.status }}
                </v-chip>
                <p class="mb-0 white--text"><small> Palabra secreta</small></p>
                <v-chip color="white" class="mb-7">
                  <b>{{
                    !!data.user.secure_password
                      ? data.user.secure_password
                      : "Sin palabra secreta"
                  }}</b>
                </v-chip>
                <v-btn large rounded depressed @click="focusOnWitness">
                  <v-icon left>mdi-map-marker-radius-outline</v-icon>
                  Ubicar victima
                </v-btn>
              </v-card-title>
              <v-card-text class="pt-6">
                <v-row>
                  <v-col cols="12">
                    <div id="map" style="width: auto; height: 450px"></div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" class="mt-6">
            <witness-detail :user="data.user" />
          </v-col>
          <v-col cols="12" md="6" class="mt-6">
            <generals-card
              :status="data.status"
              :created="data.created_at"
              :updated="data.updated_at"
            />
            <report-card :report="report" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="4" order="1" order-md="2">
        <police-list
          :policias="polices"
          :police_report_id="data.id"
          :police="data.police"
          v-on:selectPolice="updatePolice"
        />
      </v-col>
    </v-row>
    <report-modal />
    <v-snackbar v-model="showSnack" v-text="snackText" color="info" />
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import ReportModal from "../components/ReportModal";
import WitnessDetail from "../components/WitnessDetail.vue";
import ReportCard from "../components/ReportCard.vue";
import GeneralsCard from "../components/GeneralsCard.vue";
import PoliceList from "../components/PoliceList.vue";
let interval = null;

export default {
  name: "Detalles",
  mounted() {
    if (!!this.$route.params.id) {
      this.getPoliceOfficers();
      if (interval == null) {
        interval = setInterval(() => this.getPoliceOfficers(), 30000);
      }
    } else {
      this.$router.replace("/");
    }
  },
  components: {
    ReportModal,
    WitnessDetail,
    ReportCard,
    GeneralsCard,
    PoliceList,
  },
  data() {
    return {
      data: {
        user: {
          name: "",
          lastname: "",
          phone: "",
          front_image: null,
        },
        latitude: 0,
        longitude: 0,
      },
      polices: [],
      svgPolice: {
        path: "M22 4L20 2C18.85 2.64 17.4 3 16 3C14.6 3 13.14 2.63 12 2C10.86 2.63 9.4 3 8 3C6.6 3 5.15 2.64 4 2L2 4C2 4 4 6 4 8S2 14 2 16C2 20 12 22 12 22S22 20 22 16C22 14 20 10 20 8S22 4 22 4M15.05 16.45L11.97 14.59L8.9 16.45L9.72 12.95L7 10.61L10.58 10.3L11.97 7L13.37 10.29L16.95 10.6L14.23 12.94L15.05 16.45Z",
        fillColor: "#a30a26",
        fillOpacity: 1,
        strokeWeight: 0,
        rotation: 0,
        scale: 2,
        anchor: new google.maps.Point(15, 30),
      },
      svgWoman: {
        path: "M12,4A6,6 0 0,1 18,10C18,12.97 15.84,15.44 13,15.92V18H15V20H13V22H11V20H9V18H11V15.92C8.16,15.44 6,12.97 6,10A6,6 0 0,1 12,4M12,6A4,4 0 0,0 8,10A4,4 0 0,0 12,14A4,4 0 0,0 16,10A4,4 0 0,0 12,6Z",
        fillColor: "#7206bf",
        fillOpacity: 1,
        strokeWeight: 1,
        rotation: 0,
        scale: 2,
        anchor: new google.maps.Point(15, 30),
      },
      map: null,
      markers: [],
      witnessMarker: null,
      showSnack: false,
      snackText: "",
    };
  },
  methods: {
    initMap() {
      let center = {
        lat: parseFloat(this.data.latitude),
        lng: parseFloat(this.data.longitude),
      };

      this.map = new google.maps.Map(document.getElementById("map"), {
        zoom: 18,
        center: center,
      });
      const styles = {
        default: [],
        hide: [
          {
            featureType: "poi.business",
            stylers: [{ visibility: "off" }],
          },
        ],
      };
      this.map.setOptions({ styles: styles["hide"] });
      this.loadMarkers();
    },
    clearMarkers() {
      this.markers.forEach((item) => {
        item.setMap(null);
      });
      this.witnessMarker.setMap(null);
      this.markers = [];
      if (!!this.data.police) {
        this.loadPolice();
      } else {
        this.loadMarkers();
      }
    },
    loadMarkers() {
      let center = {
        lat: parseFloat(this.data.latitude),
        lng: parseFloat(this.data.longitude),
      };
      let centerProv = {};
      this.polices.forEach((item) => {
        centerProv = {
          lat: parseFloat(item.latitude),
          lng: parseFloat(item.longitude),
        };
        const marker = new google.maps.Marker({
          position: centerProv,
          icon: this.svgPolice,
          label: {
            text: item.id + " | " + item.name + " " + item.lastname,
            color: "#a30a26",
            fontWeight: "bold",
            fontSize: "14px",
          },
          title: item.id + " | " + item.name + " " + item.lastname,
        });
        this.markers.push(marker);
      });
      this.witnessMarker = new google.maps.Marker({
        position: center,
        map: this.map,
        icon: this.svgWoman,
      });
      this.appendMarkersToMap();
    },
    loadPolice() {
      let center = {
        lat: parseFloat(this.data.police.latitude),
        lng: parseFloat(this.data.police.longitude),
      };
      const marker = new google.maps.Marker({
        position: center,
        icon: this.svgPolice,
        label: {
          text:
            this.data.police.id +
            " | " +
            this.data.police.name +
            " " +
            this.data.police.lastname,
          color: "#a30a26",
          fontWeight: "bold",
          fontSize: "14px",
        },
        title:
          this.data.police.id +
          " | " +
          this.data.police.name +
          " " +
          this.data.police.lastname,
      });
      this.markers.push(marker);
      center = {
        lat: parseFloat(this.data.latitude),
        lng: parseFloat(this.data.longitude),
      };
      this.witnessMarker = new google.maps.Marker({
        position: center,
        map: this.map,
        icon: this.svgWoman,
      });
      this.appendMarkersToMap();
    },
    getPoliceOfficers() {
      const token = `${"Bearer"}${" "}${this.user.token}`;
      this.axios({
        method: "post",
        url: this.server + "/user/get-police-location",
        data: {},
        headers: {
          "Content-Type": "Application/json",
          Authorization: token,
        },
      }).then((response) => {
        this.polices = response.data;
        this.getData();
      });
    },
    getData() {
      const token = "Bearer " + this.user.token;
      let formData = new FormData();
      formData.append("id", this.$route.params.id);
      formData.append("role", "Administrador");
      this.axios({
        method: "post",
        url: this.server + "/police-reports/get",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
      }).then((response) => {
        if (Object.keys(response.data).length > 0) {
          this.data = response.data;
          if (this.map == null) {
            setTimeout(() => this.initMap(), 500);
          } else {
            this.clearMarkers();
          }
        } else {
          this.snackText = "El reporte fue cancelado";
          this.showSnack = true;
          this.$router.go(-1);
        }
      });
      if (this.data.status == "Recibido") {
        formData.append("status", "Pendiente");
        this.axios({
          method: "post",
          url: this.server + "/police-reports/update-status",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        });
      }
      if (this.data.status == "Atendido") {
        clearInterval(interval);
        interval = null;
      }
    },
    updatePolice(params) {
      let clone = [...this.markers];
      this.markers = [];
      let marker = null;
      clone.forEach((item) => {
        item.setMap(null);
        item.icon.fillColor = "#a30a26";
        item.label.color = "#a30a26";
        if (item.label.text == params) {
          item.icon.fillColor = "#1c0aa3";
          item.label.color = "#1c0aa3";
          marker = item;
        }
      });
      this.markers = clone;
      this.appendMarkersToMap(marker);
    },
    appendMarkersToMap(marker) {
      this.markers.forEach((item) => {
        item.setMap(this.map);
      });
      if (!!marker) {
        this.map.setCenter(marker.getPosition());
      }
    },
    focusOnWitness() {
      this.map.setCenter(this.witnessMarker.getPosition());
    },
  },
  computed: {
    ...mapState({
      server: (state) => state.cache.server,
      user: (state) => state.cache.user,
      report: (state) => state.cache.report,
    }),
    formattedDate() {
      if (!!this.report) {
        return moment(this.report.created_at).format("LLL");
      }
      return "Sin fecha";
    },
  },
  beforeDestroy() {
    clearInterval(interval);
    interval = null;
  },
};
</script>