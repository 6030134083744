<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere
          quisquam, cumque provident excepturi soluta ad quos culpa laudantium
          molestiae similique ab exercitationem nam veritatis nesciunt quas
          distinctio explicabo, reprehenderit ducimus.
        </h1>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {};
</script>